@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "@fortawesome/fontawesome-svg-core/styles.css";

[hidden] {
  @apply !hidden;
}

/* add to slack button */
.slack-button {
  @apply btn gap-x-3 flex-nowrap whitespace-nowrap;
  @apply bg-white border border-solid border-[#dddddd] rounded;
  @apply text-black font-[Lato,sans-serif] text-base font-semibold no-underline;
  @apply dark:bg-[#4a154B] dark:text-white dark:border-0;
  @apply btn-sm lg:btn-md text-sm lg:text-base;
}

/* large only-emoji-and-whitespace message */
.emoji-lg {
  @apply text-[2rem] leading-10;
}

/* slack legacy attachment appearance */
.bot-attachment {
  @apply flex flex-row flex-nowrap items-stretch;
}

.bot-attachment-border {
  @apply w-1 rounded-sm;
}

.bot-attachment-text {
  @apply pl-3;
}

/* slack legacy attachment border colors */
.slack-good {
  @apply bg-[#2eb886];
}

.slack-warning {
  @apply bg-[#daa038];
}

.slack-danger {
  @apply bg-[#a30200];
}

.slack-none {
  @apply bg-[#35373b];
}

/* for search excerpts */
mark {
  @apply bg-[#fde047] text-[#18181b] p-[0.125em] -m-[0.125em];
}

/* hide daisyui submenu arrows */
.menu :where(li > details > summary):after,
.menu :where(li > .menu-dropdown-toggle):after {
  @apply !hidden;
}

/* recreate daisyui v3 *-focus colors */
.bg-primary-focus {
  background-color: color-mix(
    in oklab,
    oklch(var(--p)),
    black 10%
  )
}

/* darker navbar menu hover */
.navbar {
  :where(.menu li:not(.menu-title):not(.disabled) > *:not(ul):not(details):not(.menu-title)),
  :where(.menu li:not(.menu-title):not(.disabled) > details > summary:not(.menu-title)) {
    @media (hover: hover) {
      &:not(.active):hover {
        @apply cursor-pointer outline-none;

        background-color: color-mix(
          in oklab,
          oklch(var(--p)),
          black 5%
        )
      }
    }
  }
}
